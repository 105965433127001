<script setup lang="ts">
import { sm, md } from "~/utils/breakpoints"
import { useMenuStore } from "~/stores/menu"
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"
import type { SearchProps } from "~/components/ProductTile/Overall/Search.props"
import { connect as connectImage } from "~/connectors/fragments/WrapperImage"
import { useElementHover } from '@vueuse/core'
import { customPaths } from "~/utils/constants"
import { NotificationAlertProps } from "./NotificationAlert.props"
import { checkImage } from "~/utils/checkImage"
import { MegaMenuCollection, MegaMenuOffers } from '~/components/Menu/Mega.props'
const { y } = useWindowScroll()
// TODO: Analize problem to avoid watch y if no longher used hearo search
const showSearchBarMaxPx = -1
const lastScrolledPosition = ref(0)
const isScrollingUp = useState('scrolling-up', () => true)

const { t } = useI18n()
const { notifications, removeNotification, addNotification } = useNotification()

const { overflowAuto, overflowHidden } = useBodyOverflowManager()

const {
  headerInfo,
  topHeader,
  headerAlerts,
  customButtonHeader
} = await useHeaderConnect()
const route = useRoute()

const menuStore = useMenuStore()
const userStore = useUserStore()
const { closeModal } = useModal()
const { cart, addToCartModalDesktop, lastAdded, totalAmoutOrder } = useCart()
const { isOpen: isPopupVisible } = useUIState("focus-search")

watch(isPopupVisible, (newVal) => {
  newVal ? (isLocked.value = true) : (isLocked.value = false)
})

const { categories, openSideBar } = storeToRefs(menuStore)
const { user } = storeToRefs(userStore)
const isLogged = useIsLogged()
const isOnTop = ref(false)
const showSearchBar = ref(true)
const searchBarLastShowedAt = ref<number>()
const statusMegaMenu = ref(false)
const theHeaderRef = ref()
const theMegaMenuOverlayRef = ref()
const activeMegaMenu = ref(-1)

const statusSupport = ref(false)
const supportRef = ref()
const statusDiscounts = ref(false)
const discountsRef = ref()
const statusMiniCart = ref(false)
const miniCartRef = ref()
const menuMegaRef = ref()

function updateShowSearchBar(val: boolean) {
  if (val && showSearchBar.value == false) {
    searchBarLastShowedAt.value = Date.now()
  }
  showSearchBar.value = val;
}

const { height: headerHeight} = useElementBounding(theHeaderRef)
const useHeaderHeight = useState<number>('header-height', () => headerHeight)

watch(headerHeight, (newVal) => {
useHeaderHeight.value = headerHeight.value})

const { isOpen: isDiscountSidebarOpen, switchState } = useUIState("discount-sidebar")

const showAddToCartPopup = computed(() => addToCartModalDesktop.value && !isPopupVisible.value)

const isHeaderOnHover = useElementHover(theHeaderRef)
const isOverlayOnHover = useElementHover(theMegaMenuOverlayRef)

const cartQuantity = computed(() => cart.value?.skus_count ? (cart.value.skus_count > 99 ? '99+' : cart.value.skus_count) : 0);

watch(y, (newValue) => {
  if (newValue > showSearchBarMaxPx) {
    updateShowSearchBar(true)
    isOnTop.value = false
  } else {
    updateShowSearchBar(false)
    isOnTop.value = true
  }
})

const handleScroll = () => {
  const differceScroll = Math.abs(y.value - lastScrolledPosition.value)
  const valueOfOffset = md.value ? 200 : 160
  if (differceScroll > valueOfOffset) {
    const isScrollingUpValue = y.value < lastScrolledPosition.value
    const searchBarMinDuration = 1500;
    lastScrolledPosition.value = y.value
    if (!isScrollingUpValue && typeof searchBarLastShowedAt.value == "number" && Date.now() < searchBarLastShowedAt.value + searchBarMinDuration) {
      return;
    }
    isScrollingUp.value = isScrollingUpValue
  }
}

watchThrottled(
  y,
  () => {
    handleScroll()
  },
  { throttle: 300 }
)

watch(openSideBar, (newValue) => {
  newValue
    ? overflowHidden()
    : overflowAuto()
  newValue === false ? menuStore.resetHamburgerMenu() : null
})

watch(isHeaderOnHover, (newValue) => {

  isOverlayOnHover.value = false

  if (!newValue) {
    statusMegaMenu.value = false
  }

})

watch(isOverlayOnHover, (newValue) => {

  isHeaderOnHover.value = false

  if (newValue) {
    statusMegaMenu.value = false
  }

})

watch(statusMegaMenu, (newValue) => {

  if (newValue) {
    overflowAuto()
  }

  if (!newValue && y.value <= showSearchBarMaxPx) updateShowSearchBar(false)
})

const lastQuery = useState("SEARCH_STATE")

watch(() => route.fullPath, (to, from) => {
  addToCartModalDesktop.value = false

  lastQuery.value = route.query.query

  if (!to.includes('ricerca')) {
    lastQuery.value = ""
  }
})

const currentMenuCollection = ref<number | false>(false)
const handleOpenMenu = (event: any) => {
  if (typeof event === "number") {
    activeMegaMenu.value = event
    currentMenuCollection.value = event
  }

  if (md.value) {
    if (statusMiniCart.value || statusSupport.value || statusDiscounts.value) {
      statusMiniCart.value = false
      statusSupport.value = false
      statusDiscounts.value = false
    }
    statusMegaMenu.value = true
    updateShowSearchBar(true)
  }
}

onClickOutside(supportRef, (_) => {
  if (statusSupport.value) {
    closeSidebar('support')
  }
})

onClickOutside(discountsRef, (_) => {
  if (statusDiscounts.value) {
    closeSidebar('discounts')
  }
})

onClickOutside(miniCartRef, (_) => {
  if (statusMiniCart.value) {
    closeSidebar('miniCart')
  }
})

onClickOutside(menuMegaRef, (_) => {
  if (activeMegaMenu.value !== -1) {
    statusMegaMenu.value = false
  }
})

const openSidebar = (sidebar: 'miniCart' | 'support' | 'discounts') => {
  if (sidebar === 'miniCart' && !statusMiniCart.value) {
    statusSupport.value = false
    statusDiscounts.value = false
    statusMiniCart.value = true
  }
  if (sidebar === 'support' && !statusSupport.value) {
    statusDiscounts.value = false
    statusMiniCart.value = false
    statusSupport.value = true
  }
  if (sidebar === 'discounts' && !statusDiscounts.value) {
    statusSupport.value = false
    statusMiniCart.value = false
    statusDiscounts.value = true
  }
}


watch(isDiscountSidebarOpen, (newValue) => {

  if (newValue) {
    statusDiscounts.value = true
  }

})

const closeSidebar = (sidebar: 'miniCart' | 'support' | 'discounts') => {
  if (sidebar === 'miniCart' && statusMiniCart.value) {
    statusMiniCart.value = false
  }
  if (sidebar === 'support' && statusSupport.value) {
    statusSupport.value = false
  }
  if (sidebar === 'discounts' && statusDiscounts.value) {
    statusDiscounts.value = false
    switchState(false)
  }
}

const isLocked = process.client
  ? useScrollLock(document.body)
  : ref<boolean>(false)

watch([statusMegaMenu, statusMiniCart, statusSupport, statusDiscounts], (newValues) => {
  newValues.filter(Boolean).length ? (isLocked.value = true) : (isLocked.value = false)
})

const closeHamburgerMenu = () => {
  menuStore.openCloseSidebar(false)
  // RESET SIDE MENU AND BACK TO MAIN
  menuStore.resetHamburgerMenu()
}

const lastAddedProduct = computed(() => {
  const mapLastAddedProducts = (item) => {
    return {
      productImage: connectImage(
        checkImage(item?.metadata?.product_image)
      ),
      title: item.name!,
      oldPrice: item.item.prices![0].compare_at_amount_float,
      currentPrice: item.unit_amount_float!,
      path: item?.metadata?.slug,
      quantity: item.quantity,
    } satisfies SearchProps
  }

  return mapLastAddedProducts(lastAdded.value)
})


const { fetchPreCheckoutProducts } = usePreCheckout()

if (process.client) {
  fetchPreCheckoutProducts()
}

const HamburgerMenu = computed(() => ({
  username: user?.value?.name ?? "Utente",
  categories: null,
  otherLinks: [
    {
      label: "Marche",
      path: customPaths.brands
    },
    // {
    //   label: "Idee regalo",
    //   path: "/idee-regalo"
    // },
    {
      label: "Promozioni",
      path: customPaths.promotions
    },
    {
      label: "Top Magazine",
      path: customPaths.magazineRoot
    }
  ],
  searchSettings: {
    name: "side-fake-search",
    label: "Search label",
    placeholder: "Cerca per prodotto, marca o sintomo",
    isPlaceholderAnimated: false,
    modelValue: "",
  },
  isSidebarDesktop: false,
  isLogged: isLogged.value as boolean
}))


// DISCOUNT CODES SIDEBAR
const { discountCodes, handleApplyDiscountCode, refreshDiscountCodes } = useDiscountCodes()

onMounted(async () => {
  await refreshDiscountCodes()
  })

const modalCta = computed(() => ({
  infoText: t("dialogsAndModals.addToCart.total"),
  price: totalAmoutOrder.value,
  cta: {
    path: customPaths.checkout,
    text: t("dialogsAndModals.addToCart.goToPay")
  }
}))
const handleAddToCartNotification = (productName: string) => {
  const notification: NotificationAlertProps = {
    id: Math.random(),
    type: "success",
    notificationAlertText: t(
      "dialogsAndModals.addToCart.notificationAddToCart",
      { productName: productName }
    ),
    isWithCloseIcon: true
  }
  addNotification(notification)
}

const { isOpen: isSearchBarOpened } = useUIState("focus-search")

const { isOpen: hasAddedFromAlgoliaSearchBar } = useUIState(
  "added-from-algolia-searchbar"
)

const isAModalOpened = computed(() =>
  Object.values(useState("useModal__modals")?.value ?? {}).some(
    (modalState) => modalState
  )
)


const connectedMegaMenu = computed<MegaMenuCollection>(() => {
  if (!topHeader.value) return []
  return topHeader.value.map((headerCategory) => {
    if (headerCategory?.subcategories !== null && typeof headerCategory?.subcategories !== 'undefined') {
      return headerCategory.subcategories!
    }
    return []
  })
})

const offersMegaMenu = computed<MegaMenuOffers>(() => {
  if (!topHeader.value) return []
  return topHeader.value.map((headerCategory) => {
    if (headerCategory?.featuredOffers !== null && typeof headerCategory?.featuredOffers !== 'undefined') {
      return headerCategory.featuredOffers!
    }
    return []
  })
})
</script>

<template>
  <div
    ref="theHeaderRef"
    class="sticky top-0 z-20"
    :class="{ 'pointer-events-none': !isScrollingUp }"
  >
    <div class="relative">
      <!-- Header -->
      <TheHeader
        :class="{
          '-translate-y-full transition-all duration-300': !isScrollingUp,
          'transition-all duration-300': isScrollingUp
        }"
        :status-mini-cart="statusMiniCart"
        v-bind="headerInfo"
        :hide-alert="statusMegaMenu"
        :menuLinks="topHeader"
        :custom-button="customButtonHeader"
        :shipping-message="headerAlerts?.message"
        :alert="
          headerAlerts
            ? {
                text: headerAlerts.urgentMessage,
                backgroundColor: headerAlerts.backgroundColor?.value,
                textColor: headerAlerts.textColor?.value
              }
            : undefined
        "
        :show-alert="!statusMegaMenu"
        :hide-messages="isOnTop"
        @onOpenMiniCart="openSidebar('miniCart')"
        @onOpenMenu="($event) => handleOpenMenu($event)"
        @onCloseMenuMega="statusMegaMenu = false"
        @onOpenMenuSide="menuStore.toggleSidebar"
        @on-open-support="openSidebar('support')"
        @on-open-discounts="openSidebar('discounts')"
        :showSearchBar="showSearchBar"
        :cart-quantity="cartQuantity"
        :is-popup-visible="isPopupVisible"
        :active-menu-index="statusMegaMenu && activeMegaMenu"
        :is-scrolling-up="isScrollingUp"
      />

      <div
        v-if="
          !isAModalOpened && !hasAddedFromAlgoliaSearchBar && !isSearchBarOpened
        "
        class="
          wrapper-notifications
          padded
          absolute
          z-[999999]
          mt-4
          flex
          w-full
          flex-col
          gap-2
          md:mt-12
          md:gap-4
        "
      >
        <TransitionGroup name="opacity">
          <div v-for="notification in notifications">
            <NotificationAlert
              class="flex justify-end"
              v-if="!notification.isPersistent"
              :key="notification.id"
              v-bind="notification"
              @on-close="removeNotification(notification.id)"
            />
          </div>
        </TransitionGroup>
      </div>

      <!-- MegaMenu -->
      <Transition name="slide-down">
        <MenuMega
          v-show="statusMegaMenu && topHeader"
          ref="menuMegaRef"
          @closeMenuMega="statusMegaMenu = false"
          :menu-collection="connectedMegaMenu"
          :menu-offers="offersMegaMenu"
          :current-menu-collection="currentMenuCollection"
          class="absolute z-50 hidden w-full md:block"
          :class="headerAlerts?.message ? 'top-[160px]' : 'top-[130px]'"
        />
      </Transition>
      <Transition name="opacity">
        <UtilsOverlayModal v-if="statusMegaMenu" ref="theMegaMenuOverlayRef" />
      </Transition>

      <DialogsAndModalsUtilsModal id="add-to-cart-mini-cart">
        <DialogsAndModalsAddToCart
          :product-added="lastAddedProduct"
          @close-popup="closeModal('add-to-cart-mini-cart')"
          class="m-4"
        />
      </DialogsAndModalsUtilsModal>

      <div class="grid-standard margined relative" v-if="showAddToCartPopup">
        <div
          :class="{
            'absolute right-0 top-3 z-30 -mt-48 w-[500px]': !isScrollingUp,
            'absolute right-0 top-3 z-30 w-[500px]': isScrollingUp
          }"
        >
          <DialogsAndModalsAddToCart
            :key="lastAddedProduct.title"
            :show-pointer="isScrollingUp"
            :product-added="lastAddedProduct"
          />
        </div>
      </div>

      <UtilsLeftAside
        :is-open="statusMiniCart"
        :header-alert-exist="!!headerAlerts?.urgentMessage"
        class="hidden"
      >
        <DataMiniCart ref="miniCartRef" @onClose="closeSidebar('miniCart')" />
      </UtilsLeftAside>

      <UtilsLeftAside
        :is-open="statusSupport"
        :header-alert-exist="!!headerAlerts?.urgentMessage"
        class="hidden"
      >
        <BannerHelpVerticalNeedHelp
          ref="supportRef"
          @on-close="closeSidebar('support')"
        />
      </UtilsLeftAside>

      <UtilsLeftAside
        :is-open="statusDiscounts"
        :header-alert-exist="!!headerAlerts?.urgentMessage"
      >
        <OrganismsSideDiscounts
          :discounts="discountCodes"
          @on-click-c-t-a="($event) => handleApplyDiscountCode($event)"
          @on-close="closeSidebar('discounts')"
          ref="discountsRef"
        />
      </UtilsLeftAside>
    </div>
  </div>

  <!-- Menu Mobile -->
  <Transition :name="`slide-${!md ? 'left' : 'right'}`">
    <MenuHamburgerMenu
      v-if="openSideBar"
      :="HamburgerMenu"
      :categories="categories"
      :is-sidebar-desktop="md ? true : false"
      @onCloseMenu="closeHamburgerMenu"
    />
  </Transition>
  <Transition name="opacity">
    <UtilsOverlayModal v-if="openSideBar" @click="menuStore.toggleSidebar" />
  </Transition>

  <DataPreCheckout />

  <DialogsAndModalsUtilsModal
    id="add-more"
    :enable-on-click-outside="true"
    fullscreen
  >
    <OrganismsCartModalsAddProducts
      :title="$t('dialogsAndModals.addToCart.add')"
      :text="$t('dialogsAndModals.addToCart.ourChoices')"
      :cta="modalCta"
      @on-close="closeModal('add-more')"
      @notification="handleAddToCartNotification($event)"
    />
  </DialogsAndModalsUtilsModal>
</template>
