<script lang="ts" setup>
const route = useRoute()
const currentPath = computed(() => (route ? route.path : ""))
const isHomePage = computed(() => (currentPath.value !== "/" ? true : false))
</script>

<template>
  <AppLink to="/">
    <h1 v-if="!isHomePage">
      <UtilsIcon
        fetch-priority="high"
        :preload="true"
        name="LogoGreen1.svg"
        class="h-11 w-40 flex-shrink-0 cursor-pointer"
        :altText="$t('general.topFarmaciaBest')"
        :titleText="$t('general.topFarmaciaBest')"
      />
      <span class="hidden">{{ $t("general.topFarmacia") }}</span>
    </h1>
    <UtilsIcon
      v-else
      fetch-priority="high"
      :preload="true"
      name="LogoGreen1.svg"
      class="h-11 w-40 flex-shrink-0 cursor-pointer"
      :altText="$t('general.topFarmaciaBest')"
      :titleText="$t('general.topFarmaciaBest')"
    />
  </AppLink>
</template>
