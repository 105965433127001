<script setup lang="ts">
import { AddProductsProps } from "./AddProducts.props"
import { formatMoney } from "~/utils/formatters"
import { checkEnvSite } from "~/server/utils/getAlgoliaConfig"
import { buildExcludeFilter } from "~/utils/algolia"

defineProps<AddProductsProps>()

const emit =
  defineEmits<{
    (e: "onClose"): void
    (e: "notification", productName: string): void
  }>()

const { cart, shippingInfos } = useCart()
const shippingInfosData = computed(() => shippingInfos.shippingInfos.value)

const indexProducts = useAlgoliaSearch(`TOP_PRODUCTS${checkEnvSite}`)

const { resetNotifications } = useNotification()

onMounted(() => resetNotifications())

const findItemsFromAlgolia = async () => {
  if (
    shippingInfosData?.value?.freeShipping &&
    shippingInfosData?.value?.freeShipping !== true
  ) {
    return await indexProducts
      .search({
        requestOptions: {
          clickAnalytics: true,
          //filter to be defined
          filters: `price.amount_cents:0 TO ${
            (shippingInfosData?.value?.freeShipping + 2) * 100
          } AND inStock: true`,
          hitsPerPage: 6,
          ...buildExcludeFilter({ excludeOutOfMarket: true })
        }
      })
      .then((response) => {
        const queryID = response.queryID
        const newHits = mapProducts(response.hits, queryID, "ALGOLIA")
        return newHits
      })
  }
}

const { data: products } = useAsyncData(
  "findItemsFromAlgolia",
  async () => findItemsFromAlgolia(),
  {
    immediate: true,
    server: true,
    lazy: false
  }
)

const handleCloseModal = () => {
  emit("onClose")
}

const totalCost = computed(() => {
  return formatMoney(
    cart.value?.subtotal_amount_float! +
      cart.value?.discount_amount_float! +
      cart.value?.gift_card_amount_float!
  )
})
</script>

<template>
  <div class="modal-add-product-wrapper w-screen md:w-[80vw]">
    <DialogsAndModalsStandard
      :title="title"
      fullscreen
      @on-close="handleCloseModal"
    >
      <template #content>
        <div
          class="
            relative
            flex flex-col
            gap-4
            p-4
            pb-5
            md:gap-6
            md:px-7
            md:pb-8
            md:pt-6
          "
        >
          <div class="flex flex-col gap-4 md:gap-7">
            <p v-if="text" class="dolphin-bold text-left"></p>

            <div v-if="products">
              <GAItem id="add-products" :item="products">
                <GAViewItemList> </GAViewItemList>
              </GAItem>

              <div class="grid grid-cols-1 gap-6 md:mb-0 md:grid-cols-2">
                <ProductTileOverallSearch
                  v-for="product in products"
                  :key="`product_${product.slug}`"
                  v-bind="{ ...product, theme: 'outlined-green' }"
                  @notification="emit('notification', product.title)"
                  @on-close-modal="handleCloseModal"
                  :full-width="true"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="cta" #cta>
        <div
          class="
            flex flex-col
            justify-end
            gap-2
            bg-white
            px-4
            py-4
            md:static
            md:flex-row
            md:items-center
            md:gap-6
            md:px-7
            md:pt-6
          "
        >
          <CartUtilsShippingCost
            :price="shippingInfosData?.shippingMethod?.price_amount_float!"
            :is-free="shippingInfosData?.freeShipping!"
          ></CartUtilsShippingCost>

          <div class="mb-2 flex items-center justify-between md:mb-0 md:gap-2">
            <span class="beaver-bold">{{ $t("cart.total") }}</span>
            <strong class="hyppo">{{ totalCost }}</strong>
          </div>
          <UtilsButton
            class="w-full md:w-max"
            @click="handleCloseModal"
            v-bind="cta.cta"
            disable-preheckout="true"
            :text="` ${$t('dialogsAndModals.addToCart.goToPay')} (${formatMoney(
              cta.price ?? 0
            )})`"
          />
        </div>
      </template>
    </DialogsAndModalsStandard>
  </div>
</template>
